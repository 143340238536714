import React from "react";
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import AuspiciantesGrid from "../../../Pages/institutional/Auspiciantes";
import librosFondo from '../../../assets/images/aroundTheWeb/libros.png'
import './institucionalSection.css';

const MobileInstitutionalSection = ({ navigate }) => {
  return (
    <Row justify={'center'} align={"middle"}>
      <Col xs={22} sm={22} md={0} lg={0} xl={0}>
        <div className="card-mobile">
          <h2>¿Qué es el Museo de los Niños?</h2>
          <p>
            El Museo de los Niños Abasto es un museo interactivo que recrea los espacios de una ciudad donde los chicos y chicas pueden jugar a ser médicas, camarógrafos, capitanas, marineros, bancarios, cocineros, locutoras, periodistas, enfermeros ¡y muchas cosas más!
          </p>
          <Button
            className='btn'
            type="primary"
            style={{ background: "#FF8165", width: '30%' }}
            onClick={() => navigate(`/institucional`)}
          >
            Institucional
          </Button>
        </div>
      </Col>
    </Row>
  )
}

const DesktopInstitutionalSection = ({ navigate }) => {
  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col xs={0} sm={0} md={12} lg={11} xl={11} className="desktop-main-column">
          <section className="card-desktop">
            <h2>¿Qué es el Museo de los Niños?</h2>
            <p>
              El Museo de los Niños Abasto es un museo interactivo que recrea los espacios de una ciudad donde los chicos y chicas pueden jugar a ser médicas, camarógrafos, capitanas, marineros, bancarios, cocineros, locutoras, periodistas, enfermeros ¡y muchas cosas más!
            </p>
            <Button
              className='btn'
              type="primary"
              style={{ background: "#FF8165" }}
              onClick={() => navigate(`/institucional`)}
            >
              Institucional
            </Button>
          </section>
        </Col>
        <Col xs={0} sm={0} md={12} lg={12} xl={12} className="desktop-main-column">
          <section style={{ display: 'inline-flex', justifyContent: 'end' }}>
            <img className='img-fondo2' src={librosFondo} alt="libros" />
          </section>
        </Col>
      </Row>
      <Row justify={"center"} align={"middle"}>
        <Col xs={22} sm={22} md={22} lg={24} xl={24}>
          <section style={{ height: '40rem' }}>
            <strong className="collaborators">AGRADECEMOS LA PARTICIPACIÓN DE:</strong>
            <AuspiciantesGrid />
          </section>
        </Col>
      </Row>
    </>
  )
}

const Institutional2 = () => {
  const navigate = useNavigate();

  return (
    <>
      <MobileInstitutionalSection navigate={navigate} />
      <DesktopInstitutionalSection navigate={navigate} />
    </>
  );
};

export default Institutional2;