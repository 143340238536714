import React from 'react';
import { Alert } from 'antd';

const InfoAlert = ({ message, style = '' }) => {
  return (
    <Alert
      message={message}
      type="info"
      showIcon
      style={style}
    />
  )
};

export default InfoAlert;