import photo3 from "./Sponsors/Ferrum.png";
import photo4 from "./Sponsors/clarin.png";
import photo5 from "./Sponsors/coto.png";
import photo2 from "./Sponsors/cresud.png";
import photo6 from "./Sponsors/fundacion_noble.png";
import photo7 from "./Sponsors/irsa.svg";
import photo8 from "./Sponsors/la_serenisima.png";
import photo9 from "./Sponsors/mc_donalds.png";
import photo10 from "./Sponsors/metrogas.png";
import photo11 from "./Sponsors/mitre.png";
import photo12 from "./Sponsors/movistar.png";
import photo13 from "./Sponsors/santander.png";
import photo14 from "./Sponsors/agip.jfif";
import photo15 from "./Sponsors/arcor.jfif";
import photo16 from "./Sponsors/collins.jfif";
import photo17 from "./Sponsors/rossi.jfif";
import photo18 from "./Sponsors/min_pub_tutelar.PNG";
import photo19 from "./Sponsors/el_trece.jfif";
import photo20 from "./Sponsors/fundacion_irsa.jfif";

export const auspiciantes = [
  { src: photo3, name: "Ferrum", link: "https://ferrum.com/" },
  { src: photo4, name: "clarin", link: "https://www.clarin.com/" },
  { src: photo5, name: "coto", link: "https://www.coto.com.ar/" },
  { src: photo2, name: "cresud", link: "https://www.cresud.com.ar/" },
  {
    src: photo6,
    name: "fundacion_noble",
    link: "http://www.fundacionnoble.org.ar/",
  },
  { src: photo7, name: "irsa", link: "https://www.irsa.com.ar/" },
  {
    src: photo8,
    name: "la_serenisima",
    link: "https://www.laserenisima.com.ar/",
  },
  { src: photo9, name: "mc_donalds", link: "https://www.mcdonalds.com.ar/" },
  // { src: photo10, name: "metrogas", link: "https://www.metrogas.com.ar/" },
  {
    src: photo11,
    name: "mitre",
    link: "https://www.clarin.com/radio-mitre-vivo/",
  },
  { src: photo12, name: "movistar", link: "https://www.movistar.com.ar/" },
  { src: photo13, name: "santander", link: "https://www.santander.com.ar/" },
  { src: photo14, name: "agip", link: "https://www.agip.gob.ar/" },
  { src: photo15, name: "arcor", link: "https://www.arcor.com/" },
  {
    src: photo16,
    name: "collins",
    link: "https://www.chevroletcollins.com.ar/",
  },
  { src: photo17, name: "rossi", link: "https://www.cdrossi.com/" },
  { src: photo18, name: "min_pub_tutelar", link: "https://mptutelar.gob.ar/" },
  { src: photo19, name: "el_trece", link: "https://www.eltrecetv.com.ar/" },
  {
    src: photo20,
    name: "fundacion_irsa",
    link: "http://www.fundacionirsa.org.ar/",
  },
];
