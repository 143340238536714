import React from 'react';
// import Slider from "../../ui/slider/slider";
import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import imagenFondo from '../../../assets/images/aroundTheWeb/12.png'
import { Slider2 } from '../../ui/slider/slider2';
import './homeSection.css'

const MobileHomeSection = ({ navigate }) => {
  return (
    <>
      <Row justify={'center'} align={'middle'}>
        <Col xs={22} sm={22} md={0} lg={0} xl={0} >
          <section className="home-card">
            <h1>Vení a conocer el Museo!</h1>
            <p>Adquirí tu entrada en forma on-line desde cualquier lugar con tarjeta de crédito o débito hasta una hora antes del comienzo de cada turno.</p>
            <Button
              className='btn'
              type="primary"
              style={{ background: "#FF8165", width: '30%' }}
              onClick={() => navigate(`/comprar`)}
            >
              Comprar
            </Button>
          </section>
        </Col>
      </Row>
      <Row justify={'center'} align={'middle'}>
        <Col xs={22} sm={22} md={0} lg={0} xl={0}>
          <section className='home-slider-container-mobile'>
            <Slider2 />
          </section>
        </Col>
      </Row>
    </>
  )
}

const DesktopHomeSection = ({ navigate }) => {
  return (
    <Row justify={'space-between'} align={'middle'}>
      <Col xs={0} sm={0} md={13} lg={11} xl={11} style={{ position: 'relative', margin: '5rem 0' }}>
        <section className="home-card">
          <h1>¡Vení a conocer el Museo!</h1>
          <p>Adquirí tu entrada en forma on-line desde cualquier lugar con tarjeta de crédito o débito hasta una hora antes del comienzo de cada turno.</p>
          <Button
            type="primary"
            style={{ background: "#FF8165", width: '30%' }}
            onClick={() => navigate(`/comprar`)}
          >
            Comprar
          </Button>
        </section>
        <img className='img-fondo' src={imagenFondo} alt="imagenFondo" />
      </Col>
      <Col xs={0} sm={0} md={10} lg={12} xl={12} style={{ margin: '5rem 0' }}>
        <Slider2 />
      </Col>
    </Row>
  )
}

const HomeSection = () => {
  const navigate = useNavigate();

  return (
    <div className='homesection-container'>
      <MobileHomeSection navigate={navigate} />
      <DesktopHomeSection navigate={navigate} />
    </div>
  );
};

export default HomeSection;
