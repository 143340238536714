import { Button, Table, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { INDEX_VIEW, SUCCESS_BUY_WITHOUT_CHARGE, BUY_VIEW } from "../constants";
import {
  getFiservParam,
  paymenRejection,
  paymentConfirmation,
} from "../../services/saleService";
import moment from "moment";
import StepTitle from "../../components/ui/stepTitle/StepTitle";

const DetalleCompra = () => {
  const [tickets, setTickets] = useState([]);
  const [fiserv, setFiserv] = useState(null);
  const [chargetotal, setChargeTotal] = useState("");
  const [hash, setHash] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const datetime = moment().format("YYYY:MM:DD-HH:mm:ss");

  useEffect(() => {
    const savedTickets = localStorage.getItem("savedTickets");
    if (savedTickets === "true") {
      localStorage.removeItem("savedTickets");
      navigate(BUY_VIEW, { state: {} })
    }
    if (location.state.tickets) {
      setTickets(location.state.tickets);
    }
    handleFiserv();
  }, []);

  useEffect(() => {
    const chargetotal = sumDetailsPrice();
    setChargeTotal(chargetotal);
    const getHash = async () => {
      const hash = await createExtendedHash(chargetotal, "032", datetime);
      setHash(hash);
    };
    getHash();
  }, [fiserv]);

  const handleFiserv = async () => {
    try {
      const response = await getFiservParam();
      if (response.data.status >= 200 && response.data.status < 300) {
        setFiserv(response.data.data);
        console.log(response.data.data)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formattedDate = (date) => {
    return `${moment(date).format("DD/MM/YYYY")} `;
  };

  const saleCancel = async () => {
    setLoading(true);
    try {
      const response = await paymenRejection({
        salesOrder_Id: location.state.dataShop.salesorderId,
      });
      if (response.data.status >= 200 && response.data.status < 300) {
        navigate(INDEX_VIEW);
      }
    }
    catch (error) { console.log(error); }
    finally { setLoading(false) }
  };

  const columns = [
    {
      title: "Tickets",
      dataIndex: "value",
      align: "center",
      with: 150,
      render: (value) => {
        return (
          <div>
            <p style={{ margin: 0 }}>{value}</p>
          </div>
        );
      },
    },
    {
      title: "Descripcion",
      dataIndex: "description",
      with: 150,
      align: "center",
      render: (description) => {
        return (
          <div>
            <p style={{ margin: 0 }}>{description}</p>
          </div>
        );
      },
    },
    {
      title: "Horario",
      dataIndex: "schedule",
      with: 150,
      render: (schedule) => {
        return (
          <div>
            <p style={{ margin: 0 }}>{schedule}</p>
          </div>
        );
      },
    },
    {
      title: "Precio Unitario",
      dataIndex: "price",
      align: "center",
      with: 150,
      render: (price) => {
        return (
          <div>
            <p style={{ margin: 0 }}>{price}</p>
          </div>
        );
      },
    },
  ];

  const sumDetailsPrice = () => {
    return tickets.reduce((acc, cur) => acc + cur.amount, 0);
  };
  const formattedPrice = (price) => {
    return price.toLocaleString("es-AR", {
      style: "currency",
      currency: "ARS",
    });
  };

  const bin2hex = (s) => {
    let i;
    let l;
    let o = "";
    let n;
    s += "";
    for (i = 0, l = s.length; i < l; i++) {
      n = s.charCodeAt(i).toString(16);
      o += n.length < 2 ? "0" + n : n;
    }
    return o;
  };

  const createExtendedHash = async (chargetotal, currency, txndatetime) => {
    if (fiserv === null) return;
    const stringToExtendedHash = `${fiserv.storename}${txndatetime}${chargetotal}${currency}${fiserv.sharedsecret}`;
    const binTohex = bin2hex(stringToExtendedHash);
    const hash = await hashAsync("SHA-256", binTohex);
    return hash;
  };

  const hashAsync = async (algo, str) => {
    const buf = await crypto.subtle.digest(
      algo,
      new TextEncoder("utf-8").encode(str)
    );
    const hashArray = Array.from(new Uint8Array(buf));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  };

  const SalesZeroCharge = async () => {
    const response = await paymentConfirmation({
      salesOrder_Id: location.state.dataShop.salesorderId,
      approval_Code: "Venta OnLine Sin Costo",
      oid: toString(location.state.dataShop.salesorderId),
      refnumber: null,
      status: "APROBADO",
      txndate_processed: new Date().toISOString(),
      ipgTransactionId: null,
      tdate: null,
      fail_reason: null,
      response_hash: null,
      processor_response_code: "00",
      fail_rc: null,
      terminal_id: null,
      ccbin: null,
      cccountry: null,
      ccbrand: null,
      schemeTransactionId: null,
      Documentnumber: "9999999",
      Name: "Venta OnLine Sin Costo",
      Address: null,
      payments: [],
    });

    if (response.data.status >= 200 && response.data.status < 300) {
      navigate(SUCCESS_BUY_WITHOUT_CHARGE, {
        state: { salesOrder_Id: location.state.dataShop.salesorderId },
      });
    }
  };

  const footer = () => {
    return (
      <p
        style={{ marginRight: "2rem", textAlign: "right", fontWeight: "bold" }}
      >{`Total ${formattedPrice(sumDetailsPrice())}`}</p>
    );
  };


  const handlerSubmit = () => {
    setTimeout(() => {
      localStorage.setItem("savedTickets", true);
      navigate(INDEX_VIEW, { state: null });
    }, 1000);
  }
  return (
    <div>
      <div className="table">
        <StepTitle
          textTitle={`Detalle de su compra - Fecha:
                ${formattedDate(location.state.date)}`}
          className="title"
        />
        <Table
          columns={columns}
          dataSource={tickets}
          rowKey={(record) => record.key}
          locale={{ emptyText: "  " }}
          scroll={{ x: '100%', y: "40vh" }}
          size="small"
          pagination={{ pageSize: 8 }}
          footer={footer}
        />
        <div className="button-footer">
          <Button
            type="primary"
            style={{ background: "#FF8165" }}
            onClick={saleCancel}
            disabled={loading}
          >
            {loading ? '...Cancelando' : 'Cancelar Compra'}
          </Button>
          {fiserv && (
            <form
              method="post"
              action="https://www5.ipg-online.com/connect/gateway/processing"
              target="_blank" //GM 20230427040
              onSubmit={handlerSubmit}
            >
              <input
                type="hidden"
                name="checkoutoption"
                value="combinedpage"
              />
              <input type="hidden" name="txntype" value="sale" />
              <input
                type="hidden"
                name="timezone"
                value="America/Buenos_Aires"
              />
              <input type="hidden" name="txndatetime" value={datetime} />
              <input type="hidden" name="hash_algorithm" value="SHA256" />
              <input type="hidden" name="storename" value="5930701242552" />
              <input
                type="hidden"
                name="oid"
                value={location.state.dataShop.salesorderId}
              />
              <input type="hidden" name="hash" value={hash} />
              <input type="hidden" name="chargetotal" value={chargetotal} />
              <input type="hidden" name="currency" value="032" />
              <input
                type="hidden"
                name="responseSuccessURL"
                value={fiserv.responseSuccessUrl}
              />
              <input
                type="hidden"
                name="responseFailURL"
                value={fiserv.responseFailUrl}
              />
              <input
                type="hidden"
                name="authenticateTransaction"
                value="true"
              />
              <input
                type="hidden"
                name="ThreeDSEmvCoMessageCategory"
                value="80"
              />
              {!!chargetotal ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#FF8165" }}
                >
                  Continuar
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={SalesZeroCharge}
                  style={{ background: "#FF8165" }}
                >
                  Continuar
                </Button>
              )}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetalleCompra;
