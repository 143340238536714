import React from 'react';
import { Row, Col } from 'antd';
import { auspiciantes } from './SponsorPhotos';

const AuspiciantesGrid = ({ hasTitle = false }) => {
  return (
    <Row gutter={16}>
      <h2 style={{ fontWeight: 'bold' }}>
        {hasTitle && <strong>AGRADECEMOS LA PARTICIPACIÓN DE:</strong>}
      </h2>
      {auspiciantes.map((auspiciante, index) => (
        <Col span={8} key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ width: '100%', height: '70px', backgroundColor: 'white', marginBottom: '5px' }}>
            <a href={auspiciante.link}>
              <img
                src={auspiciante.src}
                alt={auspiciante.name}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
              />
            </a>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default AuspiciantesGrid;
