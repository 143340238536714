import img_01 from "../../../assets/images/GalleryPhotos/img_01.jpg";
import img_02 from "../../../assets/images/GalleryPhotos/img_02.jpg";
import img_03 from "../../../assets/images/GalleryPhotos/img_03.jpg";
import img_04 from "../../../assets/images/GalleryPhotos/img_04.jpg";
import img_05 from "../../../assets/images/GalleryPhotos/img_05.jpg";
import img_06 from "../../../assets/images/GalleryPhotos/img_06.jpg";
import img_07 from "../../../assets/images/GalleryPhotos/img_07.jpg";
import img_08 from "../../../assets/images/GalleryPhotos/img_08.jpg";
import img_09 from "../../../assets/images/GalleryPhotos/img_09.jpg";
import img_10 from "../../../assets/images/GalleryPhotos/img_10.jpg";
import img_11 from "../../../assets/images/GalleryPhotos/img_11.jpg";
import img_12 from "../../../assets/images/GalleryPhotos/img_12.jpg";
import img_13 from "../../../assets/images/GalleryPhotos/img_13.jpg";
import img_14 from "../../../assets/images/GalleryPhotos/img_14.jpg";
import img_15 from "../../../assets/images/GalleryPhotos/img_15.jpg";
import img_16 from "../../../assets/images/GalleryPhotos/img_16.jpg";
import img_17 from "../../../assets/images/GalleryPhotos/img_17.jpg";
// import img_18 from "../../../assets/images/GalleryPhotos/img_18.jpg";
import img_19 from "../../../assets/images/GalleryPhotos/img_19.jpg";
import img_20 from "../../../assets/images/GalleryPhotos/img_20.jpg";
import img_21 from "../../../assets/images/GalleryPhotos/img_21.jpg";
import img_22 from "../../../assets/images/GalleryPhotos/img_22.jpg";
import img_23 from "../../../assets/images/GalleryPhotos/img_23.jpg";
import img_24 from "../../../assets/images/GalleryPhotos/img_24.jpg";
import img_25 from "../../../assets/images/GalleryPhotos/img_25.jpg";
import img_26 from "../../../assets/images/GalleryPhotos/img_26.jpg";
import img_27 from "../../../assets/images/GalleryPhotos/img_27.jpg";

export const photos = [
  {
    name: "img_01.jpg",
    src: img_01,
  },
  {
    name: "img_02.jpg",
    src: img_02,
  },
  {
    name: "img_03.jpg",
    src: img_03,
  },
  {
    name: "img_04.jpg",
    src: img_04,
  },
  {
    name: "img_05.jpg",
    src: img_05,
  },
  {
    name: "img_06.jpg",
    src: img_06,
  },
  {
    name: "img_07.jpg",
    src: img_07,
  },
  {
    name: "img_08.jpg",
    src: img_08,
  },
  {
    name: "img_09.jpg",
    src: img_09,
  },
  {
    name: "img_10.jpg",
    src: img_10,
  },
  {
    name: "img_11.jpg",
    src: img_11,
  },
  {
    name: "img_12.jpg",
    src: img_12,
  },
  {
    name: "img_13.jpg",
    src: img_13,
  },
  {
    name: "img_14.jpg",
    src: img_14,
  },
  {
    name: "img_15.jpg",
    src: img_15,
  },
  {
    name: "img_16.jpg",
    src: img_16,
  },
  {
    name: "img_17.jpg",
    src: img_17,
  },
  // {
  //   name: "img_18.jpg",
  //   src: img_18,
  // },
  {
    name: "img_19.jpg",
    src: img_19,
  },
  {
    name: "img_20.jpg",
    src: img_20,
  },
  {
    name: "img_21.jpg",
    src: img_21,
  },
  {
    name: "img_22.jpg",
    src: img_22,
  },
  {
    name: "img_23.jpg",
    src: img_23,
  },
  {
    name: "img_24.jpg",
    src: img_24,
  },
  {
    name: "img_25.jpg",
    src: img_25,
  },
  {
    name: "img_26.jpg",
    src: img_26,
  },
  {
    name: "img_27.jpeg",
    src: img_27,
  }
];
