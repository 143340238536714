import React, { useEffect } from "react";
import HomeSection from "../../components/sections/home/HomeSection";
import Institutional2 from "../../components/sections/compras/Institutional2";
import './home.css';

const Home = () => {
  return (
    <>
      <HomeSection />
      <Institutional2 />
    </>
  );
};
export default Home;
