import React from 'react';
import { Alert } from "antd";

const WarningAlert = ({ message, alertCloseAction }) => {
  return (
    <Alert
      message={message}
      type='warning' 
      showIcon 
      closable 
      afterClose={alertCloseAction} />
  )
};

export default WarningAlert;