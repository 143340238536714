import axios from "axios";
import {
  API_FISERV,
  API_SALES_PAYER,
  API_SALES_PAYMENT_CONFIRMATION,
  API_SALES_RECORD,
  API_SALES_RECORD_GET_TICKETS,
  API_SALES_RECORD_REJECTION,
} from "./constants/api";

export const startSale = async (salesData) => {
  try {
    const response = await axios.post(API_SALES_RECORD, salesData, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};
export const savePayer = async (payerInfo) => {
  try {
    const response = await axios.post(API_SALES_PAYER, payerInfo, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getFiservParam = async () => {
  try {
    const response = await axios.get(API_FISERV, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const paymentConfirmation = async (salesOrdeId) => {
  try {
    const response = await axios.put(
      API_SALES_PAYMENT_CONFIRMATION,
      salesOrdeId,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const paymenRejection = async (salesOrdeId) => {
  try {
    const response = await axios.put(API_SALES_RECORD_REJECTION, salesOrdeId, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const getPaymentTickets = async (salesOrdeId) => {
  try {
    const response = await axios.get(
      `${API_SALES_RECORD_GET_TICKETS}?saleorderId=${salesOrdeId}`,
      {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const result = await response.data;
    return result;
  } catch (error) {
    console.error(error);
  }
};
