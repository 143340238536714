import React from "react";
import img_01 from "../../../assets/images/GalleryPhotos/img_01.jpg";
import img_02 from "../../../assets/images/GalleryPhotos/img_02.jpg";
import img_03 from "../../../assets/images/GalleryPhotos/img_03.jpg";
import img_04 from "../../../assets/images/GalleryPhotos/img_04.jpg";
import img_05 from "../../../assets/images/GalleryPhotos/img_05.jpg";
import img_06 from "../../../assets/images/GalleryPhotos/img_06.jpg";
import img_07 from "../../../assets/images/GalleryPhotos/img_07.jpg";
import img_08 from "../../../assets/images/GalleryPhotos/img_08.jpg";
import img_09 from "../../../assets/images/GalleryPhotos/img_09.jpg";
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export function Slider2() {
  const images = [
    img_01,
    img_02,
    img_03,
    img_04,
    img_05,
    img_06,
    img_07,
    img_08,
    img_09,
  ];

  return (
    <Fade>
      {
        images.map((image, index) => (
          <div 
            key={index} 
            style={{
            display: 'flex',
            width: '100%',
            height: '400px'
          }}>
            <img
              key={index}
              src={image}
              alt="slide"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
          </div>
        ))
      }
    </Fade>
  );
}