import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";
import FacturationErrorMessage from "../../components/ui/facturation-message/FacturationErrorMessage";
import FacturationSuccessMessage from "../../components/ui/facturation-message/FacturationSuccessMessage";
import { getPaymentTickets } from "../../services/saleService";
import { paymentResponse } from "../constants";

const FacturationMock = () => {
  const location = useLocation();
  const [infoTransaction, setInfoTransaction] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const params = {};

    for (const key of searchParams.keys()) {
      params[key] = searchParams.get(key);
    }
    setInfoTransaction(params);

    if (params.result === paymentResponse.success) {
      setShowSuccess(true);
    } else {
      setShowSuccess(false);
    }
  }, []);

  const getsalesOrderId = () => infoTransaction.id || location.state.salesOrder_Id;

  const onGetTicket = async () => {
    try {
      console.log(location.state);
      const response = await getPaymentTickets(getsalesOrderId());
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(response);
      a.download = "Voucher";
      a.click();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {!infoTransaction ? (
        <Spin
          tip="Procesando"
          size="large"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className="content" />
        </Spin>
      ) : (
        <>
          {showSuccess ? (
            <FacturationSuccessMessage onGetTicket={onGetTicket} salesOrderId={getsalesOrderId()} />
          ) : (
            <FacturationErrorMessage
              failReason={unescape(infoTransaction.reason)}
            />
          )}
        </>
      )}
    </>
  );
};

export default FacturationMock;
