import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import esES from "antd/lib/locale/es_ES";
import "moment/locale/es";
import AppLayout from "./components/layouts/appLayout/AppLayout.jsx";
import Home from "./Pages/home/home.jsx";
// import Comprar from "./Pages/comprar/comprar";
import Tickets from "./Pages/tickets/Tickets.jsx";
import Institutional from "./Pages/institutional/Institutional.jsx";
import Escuelas from "./Pages/escuelas/Escuelas.jsx";
import Contactos from "./Pages/contactos/Contactos.jsx";
import FacturationMock from "./Pages/facturation-mock/FacturationMock.jsx";
import DetalleCompra from "./Pages/detalle-compra/DetalleCompra.jsx";
import DiasHorarios from "./Pages/diasHorarios/DiasHorarios.jsx";
import Corporativo from "./Pages/corporativo/Corporativo.jsx";
import Cumpleanios from "./Pages/cumpleaños/Cumpleaños.jsx";
import GaleriaDeFotos from "./Pages/galeriaDeFotos/GaleriaDeFotos.jsx";

import Comprar from "./Pages/comprar/comprar.jsx";

import "./App.css";

function App() {
  return (
    <ConfigProvider locale={esES}>
      <Router>
        <AppLayout>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/institucional" element={<Institutional />} />
            <Route path="/escuelas" element={<Escuelas />} />
            <Route path="/corporativo" element={<Corporativo />} />
            <Route path="/cumpleaños" element={<Cumpleanios />} />
            <Route path="/galeria-de-fotos" element={<GaleriaDeFotos />} />
            <Route path="/contactos" element={<Contactos />} />
            <Route path="/comprar/resultado" element={<FacturationMock />} />
            <Route path="/comprar" element={<Comprar />} />
            <Route path="/comprar/tickets" element={<Tickets />} />
            <Route path="/comprar/detalle" element={<DetalleCompra />} />
            <Route path="/dias-horarios" element={<DiasHorarios />} />
          </Routes>
        </AppLayout>
      </Router>
    </ConfigProvider>
  );
}

export default App;
