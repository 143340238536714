import React from "react";
import "./styles.css";

const StepTitle = ({ textTitle, className = "", children }) => {
  return (
    <div className={`steptitle ${className}`}>
      {children}
      <h5>{textTitle}</h5>
    </div>
  );
};

export default StepTitle;
