import React, { useRef, useState } from "react";
import { Button, message } from "antd";
import {
  MailOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  FacebookOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import tiktok from "../../assets/images/tiktok.svg";
import dibujo from "../../assets/images/aroundTheWeb/06.png";
import "./contactos.css";
import { sendContactEmail } from "../../services/contactService";

const Contactos = () => {
  const form = useRef();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setloading] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setloading(true);
    const formData = new FormData(e.target);
    const formProps = Object.fromEntries(formData);

    sendContactEmail(formProps)
      .then((result) => {
        messageApi.success("¡Mail enviado con exito!");
      })
      .catch((error) => {
        messageApi.error("Ocurrio un error al enviar el mail");
      })
      .finally(() => setloading(false));
  };

  return (
    <div className="contactos-wrapper">
      {contextHolder}
      <h1 className="titulo">
        <strong>Contacto</strong>
      </h1>
      <div className="container2">
      <div className="foto">
          <div style={{ display: "flex", justifyContent: "center"}}>
            <img className="img-fondo" src={dibujo} alt="libros" />
          </div>
        </div>
        <div className="info">
          <div>
            <MailOutlined />
            <u>
              <a
                href="mailto:info@museoabasto.org.ar"
                style={{ color: "black" }}
              >
                info@museoabasto.org.ar
              </a>
            </u>
          </div>
          <br />
          <div style={{ display: "flex" }}>
            <EnvironmentOutlined />
            Shopping Abasto | Av. Corrientes 3247, Nivel 2 <br />
            Buenos Aires | Argentina
          </div>
          <br />
          <div>
            <PhoneOutlined />
            Tel: (054-11) 4959-3700
          </div>
          <br />
          <br />
          <div className="rrss">
            <a
              href="https://www.facebook.com/museodelosninosabastobsas/"
              style={{ color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookOutlined />
            </a>
            <a
              href="https://www.instagram.com/museodelosninosabasto/"
              style={{ color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramOutlined />
            </a>
            <a
              href="https://www.tiktok.com/discover/museodelosni%C3%B1osabasto"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tiktok} width="25px" alt="tiktok logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactos;
