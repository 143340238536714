import React, { useState, useEffect } from "react"; 
import credentials from "../../settings.js";
import {
  Alert,
  DatePicker,
  Space,
  Button,
  Table,
  Spin,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "dayjs/locale/es";
import locale from "antd/es/date-picker/locale/es_ES";
import dayjs from "dayjs";
import moment from "moment";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axios from "axios";
import { getScheduleList } from "../../services/scheduleService.js";
import { useNavigate } from "react-router-dom";
import { BUY_TICKETS_VIEW } from "../constants.js";
import WarningAlert from "../../components/ui/alerts/warning/WarningAlert.jsx";
import StepTitle from "../../components/ui/stepTitle/StepTitle.jsx";
import bici from '../../assets/images/aroundTheWeb/04.png'
import "./comprar.css";

const InputNumber = ({ onChange }) => {
  const numberBetweenBounds = (number, lowerBound, upperBound) => number > lowerBound && number < upperBound;

  const onKeyDown = (e) => {
    const result = numberBetweenBounds(e.keyCode, 95, 106)
      || numberBetweenBounds(e.keyCode, 47, 58)
      || e.keyCode === 8;

    if (!result) e.preventDefault()
  }

  return (
    <input
      type="number"
      min={0}
      max={500}
      step={1}
      onKeyDown={onKeyDown}
      onChange={e => onChange(e)}
      className="tikets-input-table"
    />
  );
}


const Comprar = () => {
  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;

  const [date, setDate] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [noDates, setNoDates] = useState(false);
  const [isNotPrepareToBuy, setIsNotPrepareToBuy] = useState(true);
  const [amounts, setAmounts] = useState({});
  const [dataShop, setDataShop] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disableSearch, setDisableSearch] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [, setToken] = useState();
  const navigate = useNavigate();
  const setAlertClose = () => {
    setShowAlert(false);
  };

  const disabledDate = (current) => {
    const fecha = new Date();
    fecha.setDate(fecha.getDate() - 1);
    return current && current < fecha;
  };

  useEffect(() => {
    const login = async () => {
      const url = process.env.REACT_APP_API_URL + "/login";
      await axios
        .post(url, credentials)
        .then((response) => {
          let token = response.data.data.token;
          setToken(token);
          guardarToken(token);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    login();
  }, []);

  useEffect(() => {
    let amountFlag = true;
    for (const id in amounts) {
      if (amounts[id] > 1) {
        amountFlag = false;
      }
    }
    setIsNotPrepareToBuy(amountFlag);
  }, [amounts]);

  // useEffect(() => {
  //   (date.length && (date[0] === 'Fecha inválida' | date[1] === 'Fecha inválida')) ? setDisableSearch(true) : setDisableSearch(false)
  //   console.log(date)
  // }, [date])


  const guardarToken = (token) => {
    try {
      localStorage.setItem("token", token);
    } catch (e) {
      console.log(e);
    }
  };

  const getDataAmountsById = (data) => {
    return data.reduce((acc, cur) => {
      const key = cur.operativecalendarId;
      return { [key]: 0, ...acc };
    }, {});
  };

  const setDataAmountsById = (data) => {
    const amounts = getDataAmountsById(data);
    setAmounts(amounts);
  };
  const getDataTable = async () => {
    setAlertClose();
    setIsNotPrepareToBuy(true);

    const response = await getScheduleList(date[0], date[1]);
    setDataAmountsById(response.data.data);
    filterTableData(response.data.data);
    setLoading(false);
  };
  const filterTableData = (tableData) => {
    const filteredTable = tableData.filter(({ vacancy, hourto, date }) => {
      const vacancyDate = moment(date).format("MM-DD-YYYY");
      const vacancyDatetime = moment(`${vacancyDate} ${hourto}`);

      return vacancy > 0 && !moment().isSameOrAfter(vacancyDatetime, "minutes");
    });

    filteredTable.length ? setNoDates(false) : setNoDates(true);
    setTableData(filteredTable);
  };

  const onSubmit = () => {
    setLoading(true);
    getDataTable();
  };

  const openShowAlert = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };

  const onBuySubmit = () => {
    if (amounts[selectedRowKeys[0]] > +dataShop[0].vacancyLeft) {
      openShowAlert(
        "Los tickets seleccionados sobrepasan la disponibilidad del turno"
      );
    } else {
      amounts[selectedRowKeys[0]] > 1
        ? navigate(BUY_TICKETS_VIEW, {
          state: {
            vacancy: dataShop.vacancyLeft,
            dataShop,
            quantity: amounts[selectedRowKeys[0]],
            buyByDaysAndHoursView: false
          },
        })
        : openShowAlert("Seleccione al menos 2 entrada");
    }
  };

  const handleSelectDates = (dates, dateStrings) => {
    const formattedDates = dateStrings.map(date =>
      moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')
    )
    dateStrings[0] === 'Fecha inválida' | dateStrings[1] === 'Fecha inválida' | dateStrings[0] === "" | dateStrings[1] === "" ? setDisableSearch(true) : setDisableSearch(false)
    console.log(dateStrings)
    console.log(disableSearch)
    setDate(formattedDates);
  };

  const handleTicketsChange = (value, key) => {
    const newAmounts = { ...amounts, [key]: +value };
    setAmounts(newAmounts);
  };

  const columns = [
    {
      title: "Fecha",
      dataIndex: "date",
      align: "center",
      width: 100,
      fixed: 'left',
      render: (date) => {
        return (
          <div>
            <p style={{ margin: 0 }}>{moment(date).format("DD-MM-YYYY")}</p>
          </div>
        );
      },
    },
    {
      title: "Horario",
      dataIndex: "hourfrom",
      align: "center",
      width: 80,
      render: (hourfrom, record) => {
        return (
          <div>
            <p style={{ margin: 0 }}>
              {moment(hourfrom, "HH:mm:ss").format("HH:mm")} a {moment(record.hourto, "HH:mm:ss").format("HH:mm")}
            </p>
          </div>
        );
      },
    },
    {
      title: "Entradas Disponibles",
      dataIndex: "vacancyLeft",
      align: "center",
      width: 80,
      render: (vacancyLeft) => {
        return (
          <div>
            <p style={{ margin: 0 }}>{vacancyLeft}</p>
          </div>
        );
      },
    },
    {
      title: "Cantidad Entradas ",
      dataIndex: "quantity",
      align: "center",
      width: 80,
      fixed: 'right',
      render: (_, record) => {
        const onChange = (event) => {
          handleTicketsChange(event.target.value, record.operativecalendarId);
        }

        return <InputNumber onChange={onChange} />;
      },
    },
  ];

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setDataShop(selectedRows);
    setIsNotPrepareToBuy(false);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    type: "radio",
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onRow = (record) => {
    const { operativecalendarId } = record;
    return {
      onClick: () => {
        setDataShop([record]);
        setSelectedRowKeys([operativecalendarId]);
      },
    };
  };

  return (
    <div style={{ position: 'relative', minHeight: '80vh' }}>
      <section style={{ width: '80%', marginTop: '1rem', fontWeight: '900'}}>
        <Alert
          message="(Podés comprar tus entradas online hasta una hora antes del comienzo de cada turno)" 
          type="info"
          showIcon
        />
        <Alert
          message="Menor con CUD + adulto acompañante SIN CARGO. Las entradas se obtienen únicamente en boletería con DNI + certificado." 
          type="info"
          showIcon
        />
      </section>
      <img className='img-bici' src={bici} alt="bici" />
      <StepTitle
        textTitle="Seleccioná una fecha y buscá tu turno:"
        className="title"
      />
      <div className="search">
        <Space direction="vertical" size={6}>
          <RangePicker
            placeholder={["Fecha desde", "Fecha hasta"]}
            format="DD-MM-YYYY"
            onCalendarChange={handleSelectDates}
            disabledDate={disabledDate}
            locale={locale}
          />
        </Space>
        <div className="buttons">
          <Button
            onClick={onSubmit}
            type="primary"
            icon={<SearchOutlined />}
            style={{ background: "#FF8165" }}
            disabled={loading | disableSearch}
          >
            Buscar
          </Button>
        </div>
      </div>
      <>
        {loading ? (
          <Spin
            tip="Buscando Disponibilidad"
            size="large"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 50,
            }}
          >
            <div className="content" />
          </Spin>
        ) : (
          <div className="table">
            {!!noDates && (
              <WarningAlert
                message="No encontramos turnos para la fecha seleccionada."
                alertCloseAction={setAlertClose}
              />
            )}
            <Table
              rowSelection={rowSelection}
              onRow={onRow}
              columns={columns}
              dataSource={tableData}
              rowKey={(record) => record.operativecalendarId}
              locale={{ emptyText: "  " }}
              scroll={{ x: '100%', y: "40vh" }}
              size="small"
              pagination={{ pageSize: 8 }}
            />
            <div className="button-footer">
              <div />
              {showAlert && (
                <WarningAlert
                  message={alertMessage}
                  alertCloseAction={setAlertClose}
                />
              )}
              <Button
                type="primary"
                style={{ background: "#FF8165" }}
                onClick={onBuySubmit}
                disabled={isNotPrepareToBuy}
              >
                Empezar compra
              </Button>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default Comprar;
