import React from 'react';
import './escuelas.css';
import photo from "../../assets/images/GalleryPhotos/escuelas.jpg";
import { Button, Col, Row } from 'antd';
import pdf_visitas from "../../assets/files/VISITAS_EDUCATIVAS.pdf";
import pdf_becas from "../../assets/files/BECAS_EDUCATIVAS.pdf";

const Escuelas = () => {
  return (
    <Row justify={'center'} align={'middle'}>
      <Col xs={0} sm={0} md={8}>
        <div style={{ height: '100vh', padding: '2rem' }}>
          <img src={photo} height={'100%'} width={'100%'} alt="museo" style={{ verticalAlign: 'middle' }} />
        </div>
      </Col>
      <Col xs={24} sm={24} md={16}>
        <div style={{ padding: '1rem', textAlign: 'justify'}}>
          <h2>Visitas escolares</h2>
          <p>El objetivo de la visita escolar es aportar una experiencia que enriquezca la labor realizada por los y las docentes, a partir de los contenidos escolares trabajados en el aula. En ella, los niños y las niñas podrán apropiarse de diversos contenidos, mediante la experiencia lúdica propiciada en el Museo, teniendo al guía como agente de mediación entre los niños y las niñas, y los objetos que la muestra presenta. La visita hace hincapié en la CIUDAD y los procesos que se dan en ella. </p>
          <h2>Informes</h2>
          <p>Martes a Viernes de 13 a  16hs.</p>
          <p>Tel: 4959-3700 INT 053123/053105</p>
          <p>Mail: <a href='mailto:escuelas@museoabasto.org.ar' style={{ color: 'blue' }}><u>escuelas@museoabasto.org.ar</u></a></p>


          <div className='reser_becas'>
            <div className='reservas'>
              <h2 style={{ fontWeight: 'bold' }}>Reservas:</h2>
              <p>Descargá el instructivo y formulario de reservas</p>
              <Button style={{ backgroundColor: '#FF8165' }} type="primary" danger className='buttonDownload'>
                <a href={pdf_visitas} download="instructivo_reserva" target="_blank" rel="noopener noreferrer"> Descargar</a>
              </Button>
            </div>

            <div className='becas'>
              <h2>Programa de Becas:</h2>
              <p>Descargá el instructivo y formulario de becas</p>
              <Button style={{ backgroundColor: '#FF8165' }} type="primary" danger className='buttonDownload'>
                <a href={pdf_becas} download="instructivo_beca" target="_blank" rel="noopener noreferrer"> Descargar</a>
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Escuelas;