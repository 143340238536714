import { FISERPARAM_ID } from "../../Pages/constants";

export const API_CONTACT_EMAIL_SEND = `${process.env.REACT_APP_API_URL}/Contact`;
export const API_SCHEDULE = `${process.env.REACT_APP_API_URL}/OperativeCalendar`;
export const API_PRICE_LIST = `${process.env.REACT_APP_API_URL}/Price/List_Online`;
export const API_DISCOUNT_LIST = `${process.env.REACT_APP_API_URL}/Discount/List`;
export const API_SALES_RECORD = `${process.env.REACT_APP_API_URL}/SaleRecord`;
export const API_SALES_PAYER = `${process.env.REACT_APP_API_URL}/SaleRecord/SavePayer`;
export const API_FISERV = `${process.env.REACT_APP_API_URL}/Fiservparam?id=${FISERPARAM_ID}`;
export const API_SALES_RECORD_GET_TICKETS = `${process.env.REACT_APP_API_URL}/SaleRecord/GetTickets`;
export const API_SALES_PAYMENT_CONFIRMATION = `${process.env.REACT_APP_API_URL}/SaleRecord/PaymentConfirmation`;
export const API_SALES_RECORD_REJECTION = `${process.env.REACT_APP_API_URL}/SaleRecord/PaymentRejection`;
export const API_FISERV_GATEWAY_PROCESSING = `https://test.ipg-online.com/connect/gateway/processing`;

export const saleWithoutCharge = {
  approval_Code: "Venta OnLine Sin Costo",
  oid: "<SalesOrderId>",
  refnumber: null,
  status: "APROBADO",
  txndate_processed: "2023-05-03T19:24:08.130Z", // o como en el gateway de pagos
  ipgTransactionId: null,
  tdate: null,
  fail_reason: null,
  response_hash: null,
  processor_response_code: "00",
  fail_rc: null,
  terminal_id: null,
  ccbin: null,
  cccountry: null,
  ccbrand: null,
  schemeTransactionId: null,
  Documentnumber: "9999999",
  Name: "Venta OnLine Sin Costo",
  Address: null, //este dejarlo como NULL
};
