import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Result } from "antd";
import { INDEX_VIEW } from "../../../Pages/constants";

const FacturationSuccessMessage = ({ onGetTicket, salesOrderId }) => {
  const navigate = useNavigate();



  return (
    <Result
      status="success"
      title={`¡Compra exitosa! Su ID de compra es: ${salesOrderId}`}
      subTitle={
        <p>En los próximos minutos recibirás la entrada y la factura de tu compra en tu correo electrónico.
          <br />
          No olvides revisar tu correo no deseado y bandejas alternativas.
        </p>
      }

      extra={[
        <Button
          type="primary"
          key="console"
          style={{ background: "#FF8165" }}
          onClick={() => navigate(INDEX_VIEW)}
        >
          Volver al inicio
        </Button>,
        <Button key="buy" onClick={onGetTicket}>
          Descargar Pdf
        </Button>,
      ]}
    />
  );
};

export default FacturationSuccessMessage;
