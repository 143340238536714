export const INDEX_VIEW = "/";
export const BUY_VIEW = "/comprar";
export const DAYS_HOURS_VIEW = "/dias-horarios";
export const SALE_DETAIL = "/comprar/detalle";
export const BUY_TICKETS_VIEW = "/comprar/tickets";
export const paymentResponse = { fail: "fail", success: "success" };
export const SUCCESS_BUY_WITHOUT_CHARGE = `/comprar/resultado?result=${paymentResponse.success}`;
export const SALE_STATUS_ID = 1;
export const CURRENCY_ID = 1;
export const FISERPARAM_ID = 1;
