import React from 'react';
import { Card, Typography } from 'antd';
import Title from 'antd/es/typography/Title';

const { Text } = Typography;

const InformationCard = () => {
  return (
    <Card style={{ height: '17rem', width: '27rem' }}>
      <Title level={5} strong>
        <u>Requisitos excluyentes para entradas sin cargo</u>
      </Title>
      <div>
        <Text strong>Docentes: </Text>
        <Text>Presentar DNI + último recibo de haberes.</Text>
      </div>

      <div>
        <Text strong>Jubilados/Pensionados: </Text>
        <Text>Presentar DNI + último recibo de haberes.</Text>
      </div>

      <div>
        <Text strong>Personas con CUD: </Text>
        <Text>Presentar DNI + certificado.</Text>
      </div>
      <div>
        <Text strong>Acompañante CUD: </Text>
        <Text>Presentarse con titular del CUD.</Text>
      </div>

      <div>
        <Text strong>Menores de 2 años: </Text>
        <Text>Presentar DNI.</Text>
      </div>
      <div>
        <Text strong>Día de tu cumple: </Text>
        <Text>Válido únicamente para menores de 2 a 12 años. Sólo en boletería.</Text>
      </div>
    </Card>
  );
};

export default InformationCard;
