import React from 'react';
import tiktokLogo from '../../../assets/images/tik-tok-logo.svg';
import facebookLogo from '../../../assets/images/facebook-logo.svg';
import instagramLogo from '../../../assets/images/instagram-logo.svg';
import pdf from "../../../assets/files/tyc.pdf";
import './appFooter2.css';

const AdressSection = () => {
  return (
    <section className='footer-info-section'>
      <h2>¿Donde estamos?</h2>
      <a href="https://goo.gl/maps/hwL9f7bN5DjDWCyQ9" target="_blank" rel="noopener noreferrer">
        {/* <span className="address-details"> */}
        Shopping Abasto - {" "}
          Av. Corrientes 3247, Nivel 2 <br />
          C1193AAE - {" "}
        CABA - Argentina
        {/* </span> */}
      </a>
    </section>
  )
}

const ContactSection = () => {
  return (
    <section className='footer-info-section'>
      <h2>Contacto</h2>
      (+54 11) 4959-3700
      <br />
      <a href="mailto:info@museoabasto.org.ar">info@museoabasto.org.ar</a>
    </section>
  )
}

const SocialNetworkSection = () => {
  return (
    <section className='footer-info-section'>
      <h2>Nuestras redes</h2>
      <a href="https://www.tiktok.com/discover/museodelosni%C3%B1osabasto" target="_blank" rel="noopener noreferrer">
        <img src={facebookLogo} width="30px" alt="tiktok logo" />
      </a>
      <a href="https://www.tiktok.com/discover/museodelosni%C3%B1osabasto" target="_blank" rel="noopener noreferrer">
        <img src={instagramLogo} width="30px" alt="tiktok logo" />
      </a>
      <a href="https://www.tiktok.com/discover/museodelosni%C3%B1osabasto" target="_blank" rel="noopener noreferrer">
        <img src={tiktokLogo} width="30px" alt="tiktok logo" />
      </a>
    </section>
  )
}

const CopyRightSection = () => {
  return (
    <section className='footer-info-section'>
      Museo de Los Niños - CopyRight 2022 -
      <a href={pdf} target="_blank" rel="noopener noreferrer">
        Términos y Condiciones
      </a>
    </section>
  )
}

export default function AppFooter2() {
  return (
    <footer className='footer-main-container'>
      <section className='footer-main-section'>
        <AdressSection />
        <ContactSection />
        <SocialNetworkSection />
      </section>
      <section className='footer-secondary-section'>
        <CopyRightSection />
      </section>
    </footer>
  )
}