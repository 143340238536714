import React, { useEffect } from "react";
import { NAV_ITEMS } from "./NavItems";
import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import './appHeader.css'

const NavigationLink = ({ href, label }) => {
  return (
    <Link to={href} className="nav-link-text-white">
      <li>
        {label}
      </li>
    </Link>
  )
}

const AppHeader = () => {
  const openMobileNavigation = (nav, navToggle) => {
    nav.setAttribute("data-visible", true);
    navToggle.setAttribute("aria-expanded", true);
  }

  const closeMobileNavigation = (nav, navToggle) => {
    nav.setAttribute("data-visible", false);
    navToggle.setAttribute("aria-expanded", false);
  }

  useEffect(() => {
    const nav = document.querySelector(".primary-navigation");
    const navToggle = document.querySelector(".mobile-nav-toggle");

    nav.addEventListener('click', () => { closeMobileNavigation(nav, navToggle) })

    navToggle.addEventListener("click", () => {
      const visiblity = nav.getAttribute("data-visible");
      visiblity === "false" ? openMobileNavigation(nav, navToggle) : closeMobileNavigation(nav, navToggle)
    })
  }, [])

  const navItemsMapper = (navItem, index) => {
    return <NavigationLink
      key={index}
      index={navItem.label}
      href={navItem.href}
      label={navItem.label}
    />
  }


  return (
    <header className="primary-header flex">
      <Link to="/" className="nav-link-text-white">
        <img src={logo} alt="logo del museo" className="logo" />
      </Link>
      <button className="mobile-nav-toggle" aria-controls="primary-navigation">
        <span className="sr-only" aria-expanded="false"></span>
      </button>
      <nav>
        <ul id="primary-navigation" data-visible="false" className="primary-navigation underline-indicators flex">
          {
            NAV_ITEMS.map(navItemsMapper)
          }
        </ul>
      </nav>
    </header>
  )
}

export default AppHeader;
