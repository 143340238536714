import React from 'react';
import { Layout } from 'antd';
import AppHeader from '../../ui/header/AppHeader';
// import AppFooter from '../../ui/footer/AppFooter';
import './applayout.css';
import AppFooter2 from '../../ui/footer/AppFooter2';

const AppLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }} className="layout-container" >
      <AppHeader />
      <div className='main-container content'>{children}</div>
      <AppFooter2 />
    </Layout >
  );
};

export default AppLayout;
