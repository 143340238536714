import axios from "axios";
import { API_CONTACT_EMAIL_SEND } from "./constants/api";

export const sendContactEmail = async (data) => {
  try {
    const response = await axios.post(API_CONTACT_EMAIL_SEND, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};