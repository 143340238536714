import React from 'react';
import { Row, Col } from 'antd';
import photo from '../../assets/images/museo-3.png';
import dibujo from '../../assets/images/aroundTheWeb/03.png';
import './corporativo.css';

const CorporateLeftSection = () => {
  return (
    <Col xs={0} sm={0} md={10}>
      <section className='corporate-left-section'>
        <img src={photo} alt="museo" className='corporate-left-section-image' />
      </section>
    </Col>
  )
}

const CorporateRightSection = () => {
  return (
    <>
      <Col xs={24} sm={24} md={14}>
        <section className='corporate-right-section'>
          <h2 style={{ fontWeight: 'bold', textAlign: 'justify' }}>Corporativo</h2>
          <p style={{ textAlign: 'justify' }}>
            Realizamos eventos empresariales, Family day, y venta de entradas anticipadas
            <br />Contamos con un Auditorio con capacidad para 130 personas
          </p>
          <p>Para más información: <a href='mailto:eventos@museoabasto.org.ar' style={{ color: 'blue' }}><u>eventos@museoabasto.org.ar</u></a></p>
        </section>
      </Col>
      <img className='img-corporativo' src={dibujo} alt="img-fondo" />
    </>
  )
}

const Corporativo = () => {
  return (
    <Row className='corporativo' justify={'center'} align={'middle'}>
      <CorporateLeftSection />
      <CorporateRightSection />
    </Row>
  );
};

export default Corporativo;