import axios from "axios";
import { scheduleUrlWithDates } from "./utils";

export async function getScheduleList(dateFrom, dateTo) {
  const url = scheduleUrlWithDates(dateFrom, dateTo);

  return await axios.get(url, {
    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
  });
}
