import { Alert } from 'antd';
import React from 'react';

function Disclaimer() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '17rem', width: '27rem' }}>
      <Alert
        description={
          <ul>
            <li>Abierto de Martes a Domingos y Feriados</li>
            <li>Horarios: 12:30 a 14:50 - 15:00 a 17:20 - 17:40 a 20:00</li>
            <li>No se permite el ingreso de adultos solos al Museo.</li>
            <li>No se permite el ingreso con comida y/o bebida.</li>
            <li>Los niños y niñas tienen que permanecer en compañía de un adulto.</li>
            <li>Tarifas y horarios sujetos a modificaciones.</li>
            <li>Puede ingresar más de un adulto por niño.</li>
            <li>El Museo es para niños de 0 a 12 años.</li>
            <li>Las entradas no tienen cambio ni devolución.</li>
          </ul>
        }
        type="warning"
      />
    </div>
  );
}

export default Disclaimer;