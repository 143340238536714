import React from 'react';
import Gallery from '../../components/sections/gallery/Gallery';

const GaleriaDeFotos = () => {
  return (
    <div style={{ margin: '2rem' }}>
      <Gallery />
    </div>
  );
};

export default GaleriaDeFotos;