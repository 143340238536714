import React from 'react';
import { Row, Col } from 'antd';
import AuspiciantesGrid from './Auspiciantes';

const Institucional = () => {
  return (
    <Row className='Institucional'>
      <Col xs={0} sm={0} md={6} lg={6}>
        <div style={{
          margin: '30px',
          width: '95%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}>
          <AuspiciantesGrid hasTitle={true} />
        </div>
      </Col>
      <Col xs={24} sm={24} md={18}>
        <div style={{ padding: '40px', textAlign: 'justify', marginLeft: '30px' }}>
          <h2 style={{ fontWeight: 'bold' }}>QUÉ ES EL MUSEO DE LOS NIÑOS</h2>
          <p>
            El Museo propone un espacio enriquecedor y alternativo de encuentro que integra juego, movimiento, percepción, comprensión y expresión, incentivando la curiosidad, el interés por el conocer y la imaginación desde una mirada transformadora.
          </p>
          <p>
            Basado en la Declaración de los Derechos del Niño, ha sido diseñado para propiciar en cada niño el desarrollo de sus propios potenciales: "aprender haciendo" y "jugar y divertirse aprendiendo" son para nosotros conceptos fundamentales.
          </p>
          <p>
            Está dedicado a chicos y chicas hasta 12 años, a sus familias, educadores y a través de todos ellos a la comunidad. Y para los más pequeños, hasta 3 años, cuenta con dos salas blandas especialmente construidas para estimular su actividad.
          </p>
          <p>
            Además, cuenta con una Sala de Exposiciones y con un Auditorio donde se llevan a cabo diversas actividades.
          </p>
          <p>
            El Museo de los Niños es una Fundación sin fines de lucro. Se inauguró el 20 de abril de 1999, en la Ciudad de Buenos Aires.
          </p>
          <h2>HA SIDO DECLARADO:</h2>
          <ul>
            <li><strong>DE INTERÉS EDUCATIVO</strong> por el Ministerio de Educación de la Nación. Resolución N° 123</li>
            <li><strong>DE INTERÉS CULTURAL</strong> por la Secretaría de Cultura y Comunicación de la Presidencia de la Nación. Resolución 1895</li>
            <li><strong>DE INTERÉS CULTURAL</strong> por la Secretaría de Cultura del Gobierno de la Ciudad de Buenos Aires</li>
            <li><strong>DE INTERÉS CULTURAL</strong> por el INADI Instituto Nacional contra la Discriminación, la Xenofobia y el Racismo.</li>
            <li><strong>DE INTERÉS TURÍSTICO</strong> por la Secretaría de Turismo de la Presidencia de la Nación. Resolución N°281</li>
            <li><strong>AUSPICIO</strong> de la Secretaría de Educación del Gobierno de la Ciudad de Buenos Aires. Resolución N° 537</li>
          </ul>

        </div>
      </Col>
      <Col xs={24} sm={24} md={0} lg={0}>
        <div style={{
          margin: '30px',
          width: '95%',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',

        }}>
          <AuspiciantesGrid />
        </div>
      </Col>
    </Row>
  );
};

export default Institucional;