import React from 'react';
import { Row, Col, Image } from 'antd';
import { photos } from './galeryPhotos';

const Gallery = () => {
  const onChange = (current, prev) => {
    console.log(`current index: ${current}, prev index: ${prev}`)
  }

  return (
    <Row gutter={[8, 8]} style={{ display: 'flex', flex: '1' }}>
      <Image.PreviewGroup
        preview={{ onChange: onChange }}
      >
        {photos.map(({ name, src }, index) => (
          <Col key={index} xs={12} sm={8} md={6} lg={6} xl={4}>
            <Image src={src} alt={name} width="100%" />
          </Col>
        ))}
      </Image.PreviewGroup>
    </Row>
  );
};

export default Gallery;
