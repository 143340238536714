import React, { useEffect } from "react";
import { Button, Result, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { BUY_VIEW, INDEX_VIEW } from "../../../Pages/constants";
import { useNavigate } from "react-router-dom";
const { Paragraph, Text } = Typography;

const FacturationErrorMessage = ({ failReason }) => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   localStorage.removeItem("redireccionHome")
  // }, [])

  return (
    <Result
      status="error"
      title="Compra fallida"
      subTitle="Algo salio mal en la compra de las entradas, puedes verificar el motivo"
      extra={[
        <Button
          type="primary"
          key="console"
          style={{ background: "#FF8165" }}
          onClick={() => navigate(INDEX_VIEW)}
        >
          Volver al inicio
        </Button>,
        <Button onClick={() => navigate(BUY_VIEW)}>Reintentar la Comprar</Button>,
      ]}
    >
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            Motivo:
          </Text>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined style={{ color: "red" }} /> {failReason}
        </Paragraph>
      </div>
    </Result>
  );
};

export default FacturationErrorMessage;
