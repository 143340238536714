export const NAV_ITEMS = [
  {
    key: "comprar",
    label: "COMPRAR",
    href: "/comprar"
  },
  {
    key: "galeriaDeFotos",
    label: "GALERIA DE FOTOS",
    href: "/galeria-de-fotos"
  },
  {
    key: "diasYhorarios",
    label: "DIAS Y HORARIOS",
    href: "/dias-horarios"
  },

  {
    key: "cumpleaños",
    label: "CUMPLEAÑOS",
    href: "/cumpleaños"
  },
  {
    key: "escuelas",
    label: "ESCUELAS",
    href: "/escuelas"
  },
  {
    key: "corporativo",
    label: "CORPORATIVO",
    href: "/corporativo"
  },
  {
    key: "institucional",
    label: "INSTITUCIONAL",
    href: "/institucional"
  },
  {
    key: "contactos",
    label: "CONTACTOS",
    href: "/contactos"
  },
];
